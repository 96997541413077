import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import AboutNavbar from './AboutNavbar';
import useFetch3 from "../Requester/useFetch3";
import useFetch4 from "../Requester/useFetch4";
import domain from '../Content/domain.json'
import { Stack, Paper, TextField, Button, Typography, ToggleButtonGroup, ToggleButton, Box } from '@mui/material';
import Footer from '../Content/Footer';
import background from '../Media/background2.jpg';
import Link from '@mui/material/Link';
import logo from '../Media/logo.jpg';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Analytics from './Analytics';

const AboutConference = () => {
  const { data3, setData3 } = useFetch3();
  const { data4, setData4 } = useFetch4();
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [institution, setInstitution] = useState("");
  const [open, setOpen] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [surnameError, setSurnameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [language, setLanguage] = useState("czech");
  const [institutionError, setInstitutionError] = useState(false);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  useEffect(() => {
    let m = domain.domain + "users/api";
    setData3({ ...data3, slug: m });
    let n = domain.domain + "posts/api/section/conference";
    setData4({ ...data4, slug: n });
  }, []);

  

/*

  let handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form submission

    // Validate the fields
    if (!/^[A-Za-zěščřžýáíéĚŠČŘŽÝÁÍÉěščřžýáíéĚŠČŘŽÝÁÍÉůúÚŮóÓ]+$/.test(name)) {
      setNameError(true);
      return;
    }
    setNameError(false);

    if (!/^[A-Za-zěščřžýáíéĚŠČŘŽÝÁÍÉěščřžýáíéĚŠČŘŽÝÁÍÉůúÚŮóÓ.-\s]+$/.test(institution)) {
      setInstitutionError(true);
      return;
    }
    setInstitutionError(false);

    if (!/^[A-Za-zěščřžýáíéĚŠČŘŽÝÁÍÉěščřžýáíéĚŠČŘŽÝÁÍÉůúÚŮóÓ]+$/.test(surname)) {
      setSurnameError(true);
      return;
    }
    setSurnameError(false);

    if (!/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/.test(email)) {
      setEmailError(true);
      return;
    }
    setEmailError(false);

    try {
      // Save post to the database
      const requestBody = {
        name: name,
        surname: surname,
        email: email,
        institution: institution
      };

      const res = await fetch(domain.domain27017 + "users/api/user", {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody),
      });

      if (res.status === 201) {
        console.log("Request was successful");
        setOpen(true);
      } else {
        console.error("Request has failed");
      }
    } catch (err) {
      console.error(err);
    }
  }*/

  return (
    <div style={{ backgroundImage: `url(${background})`, backgroundRepeat: "repeat"}}>
      <Link to="/" sx={{ width: '100px', '@media(min-width: 1085px)': { display: 'none' } }}>
        <img style={{ width: '200px', display: 'block', margin: 'auto', marginBottom: '5px', }} src={logo} alt="Pedagogoium logo"
          onClick={() => (window.location.href = domain.domain)} />
      </Link>
      <Navbar></Navbar>
      <Box sx={{ left: '80px', '@media(max-width: 1400px)': { position: 'relative', marginLeft: '30%', marginBottom: '150px' }, '@media(max-width: 900px)': { position: 'relative', marginLeft: '20%', '@media(max-width: 600px)': { position: 'relative', marginLeft: '12%' }, '@media(max-width: 500px)': { position: 'relative', marginLeft: '-8%' } } }} ><AboutNavbar></AboutNavbar></Box>
      <Paper sx={{ width: '55%', display: 'block', margin: 'auto', marginTop: '50px', padding: '35px', '@media(max-width: 1400px)': { marginTop: '275px', width: '90%' }}}>
      {data4.results[0]?.content && (
  <Typography sx={{ textAlign: 'justify', padding: '5px', overflow: 'hidden' }} dangerouslySetInnerHTML={{ __html: data4.results[0].content }}>

  </Typography>
)}




      </Paper>
      <Footer></Footer>


With this code, the dialog title and button label will also change based on the selected language, ensuring that the entire dialog is rendered in the appropriate language.

   <Analytics/> </div>
  );
}

export default AboutConference;
